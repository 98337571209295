import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import nickelledMotif from "../images/ni-symbol.svg"

import * as styles from "./WpPost.module.css"

const BlogPostTemplate = ({ data: { post, recent } }) => {
  const fallbackUrl = post.featuredImage?.node?.localFile["publicURL"]
  const fallbackAlt = post.featuredImage?.node?.altText || ``

  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  }

  console.log(featuredImage.fluid)

  const postCategory = {
    name: post.categories?.nodes[0]?.name,
    slug: "/blog/" + post.categories?.nodes[0]?.slug,
  }

  return <>
    <SEO
      title={post.seo.title}
      description={
        post.seo.metaDesc ? post.seo.metaDesc : post.seo.opengraphDescription
      }
    />

    <article
      className="blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
      <Header></Header>
      <main class="relative">
        <div class="relative py-16 bg-white overflow-hidden max-w-7xl mx-auto px-4">
          <div class="relative">
            <div className={"text-lg"}>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="flex flex-col justify-center items-start">
                  <a href={postCategory.slug}>
                    <p class="text-base font-semibold text-oOrange tracking-wide uppercase">
                      {postCategory.name}
                    </p>
                  </a>
                  <h1 itemProp="headline">
                    <span class="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                      {post.title}
                    </span>
                  </h1>
                  <p class="text-gray-900 text-md font-bold">{post.date}</p>
                </div>
                {/* if we have a featured image for this post let's display it */}
                {featuredImage.fluid && 
                  <GatsbyImage
                  image={featuredImage.fluid}
                  alt={featuredImage.alt}
                  />
                }
                {!featuredImage.fluid && (
                  <img
                    src={fallbackUrl}
                    style={{ marginBottom: 50 }}
                    alt={fallbackAlt}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-oOrange hidden md:block">
          <div className="max-w-7xl mx-auto">
            <div class="flex flex-row flex-wrap md:flex-nowrap justify-between items-center p-4">
              <p class=" text-white">
                <span className="font-bold">
                  Psst! 4,000+ Companies can't be wrong.
                </span>{" "}
                You can teach your users anything with Nickelled.
              </p>
              <OutboundLink
                href="https://app.nickelled.com/account/signup"
                className="flex-shrink-1 inline-block text-center md:inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-oOrange bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
              >
                Start Teaching My Users
              </OutboundLink>
            </div>
          </div>
        </div>

        <div class="w-full pb-28 px-4" style={{ backgroundColor: "#F5F8FA" }}>
          <div class="lg:max-w-7xl mx-auto lg:pt-20">
            <div class="grid grid-cols-1 md:grid-cols-3">
              <div class="col-span-2 md:pr-10">
                <div class="prose prose-indigo prose-lg mx-auto">
                  {!!post.content && (
                    <section
                      itemProp="articleBody"
                      className={styles.articleBody}
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                  )}
                </div>
              </div>
              <div class="col-span-1">
                <div
                  class="bg-white p-6 rounded-md"
                  style={{ color: "#445664" }}
                >
                  <ul>
                    <li
                      class="rounded-md border-gray-200 border-solid border divide-y divide-gray-200"
                      style={{ backgroundColor: "#F5F8FA" }}
                    >
                      <div class="w-full p-6">
                        <div class="flex flex-row flex-nowrap items-center mb-4">
                          <h3 class="font-extrabold text-xl my-1">
                            Need to Teach Your Users?
                          </h3>
                          <img src={nickelledMotif} class="pl-4 w-20" />
                        </div>
                        <p class="my-0">
                          Our tutorials and other content are like having
                          someone sitting next to you while you learn. Take a
                          free trial and see how we make life better for your
                          users.
                        </p>
                      </div>
                      <div>
                        <div class="-mt-px flex divide-x divide-gray-200">
                          <OutboundLink
                            href="https://app.nickelled.com/account/signup"
                            target="_blank"
                            class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 px-6 text-md text-oOrange font-medium border border-transparent rounded-bl-lg "
                          >
                            <div class="w-0 flex-1 flex justify-between">
                              <span class="">Yes, train my users today</span>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="text-oOrange w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                                />
                              </svg>
                            </div>
                          </OutboundLink>
                        </div>
                      </div>
                    </li>
                    <li>
                      <br class="text-navy border-gray-300 py-4" />
                    </li>
                    <li>
                      <h3 class="uppercase font-bold text-sm tracking-normal">
                        Recent Posts
                      </h3>
                      <ul>
                        {recent.edges.map(snippet => {
                          return (
                            <li>
                              <a href={"/blog" + snippet.node.link}>
                                <div class="flex flex-row flex-nowrap items-center">
                                  {snippet.node.featuredImage?.node?.localFile
                                    ?.childImageSharp?.gatsbyImageData && (
                                    <img
                                      className={"w-24 pr-6"}
                                      src={
                                        snippet.node.featuredImage?.node
                                          ?.localFile?.childImageSharp?.gatsbyImageData
                                          .src
                                      }
                                    />
                                  )}
                                  {!snippet.node.featuredImage?.node
                                    ?.localFile?.childImageSharp?.gatsbyImageData && (
                                    <img
                                      className={"w-24 pr-6"}
                                      src={
                                        snippet.node.featuredImage?.node
                                          ?.localFile?.publicURL
                                      }
                                    />
                                  )}

                                  <p class="text-bold text-md my-0 py-4">
                                    {snippet.node.title}
                                  </p>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="w-full bg-oOrange hidden md:block">
        <div className="max-w-7xl mx-auto">
          <div class="flex flex-row flex-wrap md:flex-nowrap justify-between items-center p-4">
            <p class=" text-white">
              <span className="font-bold">
                Millions of users have learned with Nickelled.
              </span>{" "}
              Why aren't yours among them?
            </p>
            <OutboundLink
              href="https://app.nickelled.com/account/signup"
              className="flex-shrink-1 inline-block text-center md:inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-oOrange bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
            >
              Improve My Training Today
            </OutboundLink>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </article>
  </>;
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostById($id: String!) {
  post: wpPost(id: {eq: $id}) {
    id
    excerpt
    content
    title
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
    }
    categories {
      nodes {
        name
        slug
      }
    }
    seo {
      title
      metaDesc
      opengraphPublishedTime
      opengraphTitle
      opengraphUrl
      opengraphPublisher
      opengraphSiteName
      opengraphType
      opengraphDescription
    }
  }
  recent: allWpPost(limit: 5, sort: {fields: date, order: DESC}) {
    edges {
      node {
        id
        title
        date
        featuredImage {
          node {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
          }
        }
        link
      }
    }
  }
}
`
